class App {
  constructor() {
    this.addEventListeners()
  }

  addEventListeners() {
    $(document).on('click', '.js-policy', this.openPolicy)
      .on('click', '.js-modal__close', this.closeModal)
      .on('click', '.js-policy__apply', this.applyPolicy)
      .on('click', '.js-productImageBlock__img', this.openPreviewGallery)
      .on('click', '.js-subCategoryButton', this.openSubCategory)
      .on('click', '.js-subCategoryButton_close', this.closeSubCategory)
  }

  changeModal = (args = {}) => {
    const { title, content, controls, isActive } = args;
    $('.js-modal__window__title').text(title || '')
    $('.js-modal__window__content').html(content || '')
    if (controls !== undefined) {
        $('.js-modal__window__controls').html(controls || '');
    }
    $('.js-modal')[isActive ? 'addClass' : 'removeClass']('active')
  }

  openPolicy = async () => {
    await fetch(SITE_DIR + 'personal/cart/license.php')
    .then(res => res.text())
    .then((data) => {
      let html = $('<html />').html(data)
      let title = html.find('title')[0].innerText
      let content = html.find('.companyHistory__container')[0].innerHTML
      
      this.changeModal({
        title: title,
        content: content,
        controls: undefined,
        isActive: true
      })
    })
  }

  applyPolicy = () => {
    fetch('/todorequest')
    this.closeModal()
  }

  closeModal = () => {
    this.changeModal()
  }

  openPreviewGallery = event => {
    event.preventDefault()
    let $target = $(event.target);
    let parent = $target.closest('.owl-stage');
    let childs = [...parent.children().not('.cloned')]
    let urls = childs.map(child => $(child).find('.js-productImageBlock__img').attr('href'))

    $.fancybox(urls, {
      'padding': 0,
      'transitionIn': 'none',
      'transitionOut': 'none',
      'type': 'image',
      'changeFade': 0
    });
  }

  openSubCategory = () => {
    $('.js-subCategoryButton').addClass('active')
  }

  closeSubCategory = ()=>{
    $('.js-subCategoryButton').removeClass('active')
  }
}

window.APP = new App()